import React from "react";
import { Form } from "./form";

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row m-row">
              <div
                className="col-md-6 col-12 intro-text"
              >
                <h1>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1>
                <p>{props.data ? props.data.paragraph : "Loading"}</p>
                {/* <a
                  href="#features"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Learn More
                </a>{" "} */}
              </div>
              <div className="col-md-6 col-12 intro-form">
                <div
                  style={{
                    borderRadius: "15px",
                    padding: "15px",
                    backgroundColor: "#f0f8ff9c",
                  }}
                >
                  <div className="section-title">
                    <h2>Get In Touch</h2>
                    <p>Please fill out the form below.</p>
                  </div>
                  <Form />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
