import React from "react";

export const Testimonials = (props) => {
  return (
    <div id="testimonials">
      <div className="container">
        <div className="section-title text-center">
          <h2>Press Releasing</h2>
        </div>
        <div className="testimonial-image">
          {props.data
            ? props.data.map((d, i) => <img key={i} src={d.img} alt="" />)
            : "loading"}
        </div>
      </div>
    </div>
  );
};
