import { useState } from "react";
import React from "react";
import { toast } from 'react-toastify';

const initialState = {
  name: "",
  email: "",
  phoneNumber: "",
  NumberOfAttendees: '',
};
export const Form = () => {
  const [{ name, email, NumberOfAttendees, phoneNumber }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch('https://api.gammacities.com/api/forms/submit-event-gala-form', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({name, email, NumberOfAttendees: Number(NumberOfAttendees), phoneNumber})
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      clearState()
      toast.success("Thank you for submitting your message.", {
        position: "top-center",
        hideProgressBar: true,
        theme: "colored",
      });
    })
    .catch(error => {
      toast.error('There is a problem!', {
        position: "top-center",
        hideProgressBar: true,
        theme: "colored",
      });
    });
  };
  return (
    <form name="sentMessage" validate >
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <input
              type="text"
              id="name"
              value={name}
              name="name"
              className="form-control"
              placeholder="Name"
              required
              onChange={handleChange}
            />
            <p className="help-block text-danger"></p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              className="form-control"
              placeholder="Email"
              required
              onChange={handleChange}
            />
            <p className="help-block text-danger"></p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <input
              type="phone"
              id="phoneNumber"
              name="phoneNumber"
              value={phoneNumber}
              className="form-control"
              placeholder="Phone Number"
              required
              onChange={handleChange}
            />
            <p className="help-block text-danger"></p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <input
              type="number"
              id="NumberOfAttendees"
              name="NumberOfAttendees"
              value={NumberOfAttendees}
              className="form-control"
              placeholder="Number of attendees"
              required
              onChange={handleChange}
            />
            <div className="help-block text-danger"></div>
          </div>
        </div>
      </div>
      <div id="success"></div>
      <button type="submit" disabled className="btn btn-custom btn-lg">
        Send
      </button>
      <div className="text-danger">Thank you for your interest! Registration for this event is now full.
      We've reached capacity for this event. Stay tuned for future opportunities!</div>
    </form>
  );
};
